import { LiabilityTypeChoices } from '@integrations/xplan-types'
import * as Yup from 'yup'

export const getUrl = (): string => {
  const url = process.env.NEXT_PUBLIC_RELAY_ENDPOINT
  if (url) {
    return url
  } else {
    throw new Error('Could not find NEXT_PUBLIC_RELAY_ENDPOINT')
  }
}
export const isDEV = process.env.NODE_ENV === 'development'

export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']
export const MIN_FILE_SIZE = 1024

export const yupImageUploadSchema = Yup.mixed()
  .test({
    name: 'fileFormat',
    message: 'Unsupported Format',
    test: (value) => (value && value.type ? SUPPORTED_FORMATS.includes(value.type) : true),
  })
  .test('fileSize', 'File is too small, must be greater than 1KB', (value) =>
    value && value.size ? value.size >= MIN_FILE_SIZE : true
  )
export const {
  // This write key is associated with https://segment.com/nextjs-example/sources/nextjs.
  NEXT_PUBLIC_ANALYTICS_WRITE_KEY,
} = process.env

export const TRACKING_ENABLED = NEXT_PUBLIC_ANALYTICS_WRITE_KEY ? true : false

// nop
export const xplanCountries = [
  'Afghanistan',
  'Åland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bonaire, Saint Eustatius and Saba',
  'Bosnia',
  'Botswana',
  'Bouvetoya',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chagos Archipelago',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos Islands',
  'Colombia',
  'Comoros',
  'Congo, Democratic Republic of (Zaire)',
  'Congo, Peoples Republic of',
  'Congo',
  'Cook Islands',
  'Costa Rica',
  "Côte D'Ivoire",
  'Croatia',
  'Cuba',
  'Curaçoa',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Faeroe Islands',
  'Falkland Islands (Malvinas)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Great Britain',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea-Bissau',
  'Guinea',
  'Guyana',
  'Haiti',
  'Heard and McDonald Islands',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea, Democratic Peoples Republic of',
  'Korea, Republic of',
  'Kuwait',
  'Kyrgyzstan',
  'Lao Peoples Democratic Republic',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libyan Arab Jamahiriya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia, Federated States Of',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestinian Territory',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn Island',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russian Federation',
  'Rwanda',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia (Slovak Republic)',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'St. Barthélemy',
  'St. Helena',
  'St. Kitts and Nevis',
  'St. Lucia',
  'St. Martin (French part)',
  'St. Pierre and Miquelon',
  'St. Vincent and the Grenadines',
  'Sudan',
  'Suriname',
  'Svalbard and Jan Mayen Islands',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States Minor Outlying Islands',
  'United States of America',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City State',
  'Venezuela',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna Islands',
  'Western Sahara',
  'Yemen',
  'Yugoslavia',
  'Zaire',
  'Zambia',
  'Zimbabwe',
]

export enum LiabilityTypeGroupChoices {
  Current = '1',
  LongTerm = '2',
  Contingent = '3',
}

export enum LiabilityOwnerChoices {
  Client = 'Client',
  Partner = 'Partner',
  Joint = 'Joint',
  Complex = 'Complex',
}

export enum LiabilityStatusChoices {
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  Current = 'Current',
}

export enum LiabilityLoanTermType {
  Months = 'Months',
  Years = 'Years',
}

export enum LiabilityRepaymentType {
  PrincipalAndInterest = '1',
  InterestOnly = '2',
  InterestCapitalised = '3',
  PrePaidInterest = '4',
}

export enum AssetOwnerChoices {
  Client = 'Client',
  Partner = 'Partner',
  Joint = 'Joint',
  Complex = 'Complex',
}

export enum AssetTypeChoices {
  AccountsReceivable = '702',
  Annuity = '401',
  Antiques = '501',
  Artwork = '502',
  Bonds = '201',
  CashOnHand = '101',
  CurrentSavings = '102',
  FixedDeposits = '103',
  Franchises = '707',
  Goodwill = '706',
  HolidayHome = '303',
  HouseholdContents = '503',
  Inventory = '703',
  Investment = '708',
  InvestmentProperty = '302',
  Investments = '709',
  Jewellery = '504',
  KiwiSaver = '404',
  Livestock = '712',
  ManagedInvestments = '205',
  MotorVehicle = '505',
  Other = '405',
  OtherInvestments = '299',
  OtherLifeStyle = '506',
  OtherLifestyleProperty = '304',
  PatentsAndCopyrights = '705',
  Pension = '402',
  PlantAndMachinery = '701',
  PrepaidExpenses = '704',
  PrimaryResidence = '301',
  SecondaryResidentialProperty = '300',
  Shares = '202',
  Superannuation = '403',
  UnitTrusts = '203',
  WrapAccount = '204',
}
interface DebtTypeLabels {
  [key: string]: string
}
// Used on the debt summary page
export const debtTypeLabels: DebtTypeLabels = {
  '': 'Select an option',
  [LiabilityTypeChoices.CreditCard]: 'Credit Card',
  [LiabilityTypeChoices.PersonalLoan]: 'Personal Loan',
  [LiabilityTypeChoices.Overdraft]: 'Overdraft',
  [LiabilityTypeChoices.CarLoan]: 'Car Loan',
  [LiabilityTypeChoices.OutstandingPayment]: 'Outstanding Payment',
  [LiabilityTypeChoices.OtherCurrentLoan]: 'Other Current Loan',
  [LiabilityTypeChoices.OtherLongTermLoan]: 'Other Long Term Loan',
  [LiabilityTypeChoices.PrimaryResidenceMortgage]: 'Primary Residence Mortgage',
  [LiabilityTypeChoices.PrimaryResidenceIslamicMortgage]: 'Primary Residence Islamic Mortgage',
  [LiabilityTypeChoices.BuyToLetMortgage]: 'Buy To Let Mortgage',
  [LiabilityTypeChoices.AdditionalPropertyMortgage]: 'Additional Property Mortgage',
  [LiabilityTypeChoices.InvestmentPropertyMortgage]: 'Investment Property Mortgage',
  [LiabilityTypeChoices.StudentLoan]: 'Student Loan',
}
// Used for the debt type dropdown in the debt form
export const debtTypes = [
  { key: '', label: 'Select an option' },
  { key: LiabilityTypeChoices.CreditCard, label: 'Credit Card' },
  { key: LiabilityTypeChoices.PersonalLoan, label: 'Personal Loan' },
  { key: LiabilityTypeChoices.Overdraft, label: 'Overdraft' },
  { key: LiabilityTypeChoices.CarLoan, label: 'Car Loan' },
  { key: LiabilityTypeChoices.OutstandingPayment, label: 'Outstanding Payment' },
  { key: LiabilityTypeChoices.OtherCurrentLoan, label: 'Other Current Loan' },
  { key: LiabilityTypeChoices.OtherLongTermLoan, label: 'Other Long Term Loan' },
  { key: LiabilityTypeChoices.PrimaryResidenceMortgage, label: 'Primary Residence Mortgage' },
  { key: LiabilityTypeChoices.AdditionalPropertyMortgage, label: 'Additional Property Mortgage' },
  { key: LiabilityTypeChoices.InvestmentPropertyMortgage, label: 'Investment Property Mortgage' },
  { key: LiabilityTypeChoices.StudentLoan, label: 'Student Loan' },
]

export const assetTypes = [
  { key: '', label: 'Select an option' },
  { key: AssetTypeChoices.Annuity, label: 'Annuity' },
  { key: AssetTypeChoices.Antiques, label: 'Antiques' },
  { key: AssetTypeChoices.Artwork, label: 'Artwork' },
  { key: AssetTypeChoices.Bonds, label: 'Bonds' },
  { key: AssetTypeChoices.CashOnHand, label: 'Cash On Hand' },
  { key: AssetTypeChoices.CurrentSavings, label: 'Current Savings' },
  { key: AssetTypeChoices.FixedDeposits, label: 'Fixed Deposits' },
  { key: AssetTypeChoices.HolidayHome, label: 'Holiday Home' },
  { key: AssetTypeChoices.HouseholdContents, label: 'Household Contents' },
  { key: AssetTypeChoices.Jewellery, label: 'Jewellery' },
  { key: AssetTypeChoices.KiwiSaver, label: 'KiwiSaver' },
  { key: AssetTypeChoices.ManagedInvestments, label: 'Managed Investments' },
  { key: AssetTypeChoices.MotorVehicle, label: 'Motor Vehicle' },
  { key: AssetTypeChoices.OtherInvestments, label: 'Other Investments' },
  { key: AssetTypeChoices.OtherLifeStyle, label: 'Other Lifestyle' },
  { key: AssetTypeChoices.OtherLifestyleProperty, label: 'Other Lifestyle Property' },
  { key: AssetTypeChoices.Pension, label: 'Pensions' },
  { key: AssetTypeChoices.PrimaryResidence, label: 'Primary Residence' },
  { key: AssetTypeChoices.InvestmentProperty, label: 'Investment Property' },
  { key: AssetTypeChoices.SecondaryResidentialProperty, label: 'Secondary Residential Property' },
  { key: AssetTypeChoices.Shares, label: 'Shares' },
  { key: AssetTypeChoices.Superannuation, label: 'Superannuation' },
  { key: AssetTypeChoices.UnitTrusts, label: 'Unit Trusts' },
  { key: AssetTypeChoices.WrapAccount, label: 'Wrap Account' },
]
